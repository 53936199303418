<template>
    <section class="dashboard new-updated-design" v-if="permissionExist('view_clients_dashboard')">
        <div v-if="permissionExist('view_super_admin_dashboard')" class=""></div>
        <div v-else-if="permissionExist('view_clients_dashboard')" class="">
            <div class="row">
                <div class="col-md-3 grid-margin stretch-card dashboard-box box_01">
                    <div class="card">
                        <div class="card-body">
                            <p class="card-title mb-0">{{ $t('total-spots') }}</p>
                            <div
                                    class="dashboard-box-counter"
                            >
                                <h3 class="">{{total_spots}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 grid-margin stretch-card dashboard-box box_02">
                    <div class="card">
                        <div class="card-body">
                            <p class="card-title mb-0">{{ $t('this-year') }}</p>
                            <div
                                    class="dashboard-box-counter"
                            >
                                <h3 class="">{{this_year}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 grid-margin stretch-card dashboard-box box_03">
                    <div class="card">
                        <div class="card-body">
                            <p class="card-title mb-0">{{quarter_name}}</p>
                            <div
                                    class="dashboard-box-counter"
                            >
                                <h3>{{quarter_count}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 grid-margin stretch-card dashboard-box box_04">
                    <div class="card">
                        <div class="card-body">
                            <p class="card-title mb-0">{{month_name}}</p>
                            <div
                                    class="dashboard-box-counter"
                            >
                                <h3>{{month_count}}</h3>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row" style="margin: 0px;">
                <div class="col-lg-6 col-md-12 stretch-card new-stretch-card stretch-card-padding">
                    <b-form-group
                            id="input-group-1"
                            :label="$t('date-from')"
                            label-for="input-1"
                            class="grid-margin new-grp"
                    >
                        <b-form-input
                                style="cursor: pointer;"
                                id="input-1"
                                v-model="form.date_from"
                                type="date"
                                :placeholder="$t('date-from')"
                                required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                            id="input-group-1"
                            :label="$t('date-to')"
                            label-for="input-1"
                            class="ml-1 mlt-0 grid-margin new-grp"
                    >
                        <b-form-input
                                style="cursor: pointer;"
                                id="input-1"
                                v-model="form.date_to"
                                type="date"
                                :placeholder="$t('date-to')"
                                required
                        ></b-form-input>
                    </b-form-group>
                    <div class="btns-flex">
                    <div class="ml-2 mt-4 grid-margin new-grp">
                        <button @click="filterData()" class="btn btn-info text-white">{{ $t('filter-btn') }}</button>
                    </div>
                    <div class="ml-2 mt-4 grid-margin new-grp">
                        <button @click="resetForm()" class="btn btn-warning  text-white">{{ $t('reset-btn') }}
                        </button>
                    </div>
                    <div class="ml-2 mt-4 grid-margin new-grp total-spots-style">
                        {{ totalSpots ? totalSpots: '0' }}
                    </div>
                    </div>

                </div>

            </div>
            <div class="row">
                <!-- <div class="col-lg-6 col-md-12 grid-margin stretch-card line-bar-chart">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{{ $t('project-validity') }}</h4>
                        </div>
                        <div class="card-body">
                            <div id="spot_chart">
                                <totalSpotValidity></totalSpotValidity>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-lg-6 col-md-12 grid-margin stretch-card line-bar-chart">
                    <div class="card">
                        <div class="card-header drop-down-header">
                            <h4 class="card-title">{{ $t('spots-per-month') }}</h4>
                            <div>
                                <b-form-select style="cursor: pointer;" v-model="dropDownComponentForSpotsPerMonth">
                                    <b-form-select-option value="0">{{ $t('pie-chart') }}</b-form-select-option>
                                    <b-form-select-option value="1">{{ $t('bar-chart') }}</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="card-body">
                            <div id="spot_chart">
                                <spotChartPieCountPerMonth ref="spotChartPieCountPerMonth" v-if="dropDownComponentForSpotsPerMonth == 0" :date_from="form.date_from" :date_to="form.date_to" :projectID="projectID"></spotChartPieCountPerMonth>
                                <spotChartCanvasCountPerMonth ref="spotChartCanvasCountPerMonth" v-if="dropDownComponentForSpotsPerMonth == 1" :date_from="form.date_from" :date_to="form.date_to" :projectID="projectID"></spotChartCanvasCountPerMonth>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 grid-margin stretch-card chat-box m-chart">
                    <div class="card">
                        <div class="card-header drop-down-header">
                            <h4 class="card-title">{{ $t('spots-per-location') }}</h4>
                            <div>
                                <b-form-select style="cursor: pointer;" v-model="dropDownComponentForLocation">
                                    <b-form-select-option value="0">{{ $t('pie-chart') }}</b-form-select-option>
                                    <b-form-select-option value="1">{{ $t('bar-chart') }}</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="card-body" >
                            <spotLocationCountPie ref="spotLocationCountPie" v-if="dropDownComponentForLocation == 0" :date_from="form.date_from" :date_to="form.date_to"></spotLocationCountPie>
                            <spotLocationCountBar ref="spotLocationCountBar" v-if="dropDownComponentForLocation == 1" :date_from="form.date_from" :date_to="form.date_to"></spotLocationCountBar>
                            
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 grid-margin stretch-card chat-box m-chart">
                    <div class="card">
                        <div class="card-header drop-down-header">
                            <h4 class="card-title">{{ $t('spots-per-type') }}</h4>
                            <div>
                                <b-form-select style="cursor: pointer;" v-model="dropDownComponentForSpotType">
                                    <b-form-select-option value="0">{{ $t('pie-chart') }}</b-form-select-option>
                                    <b-form-select-option value="1">{{ $t('bar-chart') }}</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="card-body">
                            <spotTypeCountPie ref="spotTypeCountPie" v-if="dropDownComponentForSpotType == 0" :date_from="form.date_from" :date_to="form.date_to" :projectID="projectID"></spotTypeCountPie>
                            <spotTypeCountBar ref="spotTypeCountBar" v-if="dropDownComponentForSpotType == 1" :date_from="form.date_from" :date_to="form.date_to" :projectID="projectID"></spotTypeCountBar>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 grid-margin stretch-card chat-box m-chart">
                    <div class="card">
                        <div class="card-header drop-down-header">
                            <h4 class="card-title">{{ $t('spots-per-category') }}</h4>
                            <div>
                                <b-form-select style="cursor: pointer;" v-model="dropDownComponentForCategory">
                                    <b-form-select-option value="0">{{ $t('pie-chart') }}</b-form-select-option>
                                    <b-form-select-option value="1">{{ $t('bar-chart') }}</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="card-body">
                            <categoryCountPie ref="categoryCountPie" v-if="dropDownComponentForCategory == 0" :date_from="form.date_from" :date_to="form.date_to" :projectID="projectID"></categoryCountPie>
                            <categoryCountBar ref="categoryCountBar" v-if="dropDownComponentForCategory == 1" :date_from="form.date_from" :date_to="form.date_to" :projectID="projectID"></categoryCountBar>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-6  grid-margin stretch-card chat-box" v-for="(question, index) in questions"
                     :key="index">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{{question.question}}</h4>
                        </div>
                        <div class="card-body">
                            <pieChartPerQuestion :questionData="question.answers"></pieChartPerQuestion>
                        </div>
                    </div>
                </div> -->
                <div class="col-lg-6 col-md-12 grid-margin stretch-card chat-box m-chart" v-for="(question, index) in questions"
                     :key="index">
                    <div v-if="showQuestionData"  class="card">
                        <div class="card-header drop-down-header">
                            <h4 class="card-title">{{question.question}}</h4>
                            <div>
                                <select style="cursor: pointer;" name="LeaveType" class="custom-select" v-model="question.chartType">
                                    <option value="true">{{ $t('pie-chart') }}</option>
                                    <option value="false">{{ $t('bar-chart') }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="card-body">
                                <spotQuestionCountPie ref="spotQuestionCountPie" :chartType="question.chartType" :questionData="question" :key="question.chartType" :date_from="form.date_from" :date_to="form.date_to" :projectID="projectID"></spotQuestionCountPie>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>
<script>
import { ref, provide } from "vue";

const date_form = ref("");
const date_to = ref("");
// provide("form.date_form", date_form);
// provide("form.date_to", date_to);

    // import totalSpotValidity from "../components/charts/am-charts/totalSpotValidity";
    // import spotChartCanvasCountPerMonth from "../components/charts/am-charts/spotChartCanvasCountPerMonth";
    // import spotLocationCountPie from "../components/charts/am-charts/spotLocationCountPie";
    // import spotTypeCountPie from "../components/charts/am-charts/spotTypeCountPie";
    // import categoryCountPie from "../components/charts/am-charts/categoryCountPie";
    // import categoryCountBar from "../components/charts/am-charts/categoryCountBar";
    // import spotQuestionCountPie from "../components/charts/am-charts/spotQuestionCountPie";
    // // import spotQuestionCountBar from "../components/charts/am-charts/spotQuestionCountBar";
    // import spotLocationCountBar from "../components/charts/am-charts/spotLocationCountBar";
    // import spotTypeCountBar from "../components/charts/am-charts/spotTypeCountBar";
    // import spotChartPieCountPerMonth from "../components/charts/am-charts/spotChartPieCountPerMonth";
    const spotChartCanvasCountPerMonth = () => import("../components/charts/am-charts/spotChartCanvasCountPerMonth");
    const spotLocationCountPie = () => import("../components/charts/am-charts/spotLocationCountPie");
    const spotTypeCountPie = () => import("../components/charts/am-charts/spotTypeCountPie");
    const categoryCountPie = () => import("../components/charts/am-charts/categoryCountPie");
    const categoryCountBar = () => import("../components/charts/am-charts/categoryCountBar");
    const spotQuestionCountPie = () => import("../components/charts/am-charts/spotQuestionCountPie");
    const spotLocationCountBar = () => import("../components/charts/am-charts/spotLocationCountBar");
    const spotTypeCountBar = () => import("../components/charts/am-charts/spotTypeCountBar");
    const spotChartPieCountPerMonth = () => import("../components/charts/am-charts/spotChartPieCountPerMonth");

    import API from '@/api'

    export default {
        name: 'Dashboard',
        components: {
    // totalSpotValidity,
    spotChartCanvasCountPerMonth,
    spotLocationCountPie,
    spotTypeCountPie,
    categoryCountPie,
    spotQuestionCountPie,
    spotLocationCountBar,
    spotTypeCountBar,
    categoryCountBar,
    // spotQuestionCountBar,
    spotChartPieCountPerMonth
},
        data() {
            return {
                dropDownComponentForLocation: 0,
                dropDownComponentForCategory:0,
                dropDownComponentForSpotType:0,
                dropDownComponentForQuestion:0,
                dropDownComponentForSpotsPerMonth:1,
                form: {
                    date_from: '',
                    date_to: '',
                    client_id: '',
                    project_id: '',
                },
                total_spots: '',
                this_year: '',
                quarter_name: '',
                quarter_count: '',
                month_name: '',
                month_count: '',
                questions: [],
                selected_client_id: '',
                dataTE: '1',
                deviceIDToken: '', 
                totalSpots:0,
                showQuestionData: true,
            };
        },
        // provide() {
        //     return {
        //         date_from: this.form.date_from,
        //         date_to: this.form.date_to
        //     };
        // },
        methods: {
            onChange(question,index, event) {
                if(event.target.value === 'pie') {
                    this.questions[index].pieChart = true;
                    this.questions[index].barChart = false;
                }
                if(event.target.value === 'bar') {
                    this.questions[index].pieChart = false;
                    this.questions[index].barChart = true;
                }
            },
            resetForm() {
                this.form.date_from = '';
                this.form.date_to = '';
                // this.$EventBus.$emit('resetForm', this.form);
                this.getQuestionsData();
                this.getTotalSpotsByFilter();
                if(this.dropDownComponentForSpotsPerMonth == 1){
                    this.$refs.spotChartCanvasCountPerMonth.getChartData();
                }
                else{
                    this.$refs.spotChartPieCountPerMonth.getChartData();
                }
                if(this.dropDownComponentForLocation == 0){
                    this.$refs.spotLocationCountPie.getChartData();
                }
                else{
                    this.$refs.spotLocationCountBar.getChartData();
                }
                if(this.dropDownComponentForSpotType == 0){
                    this.$refs.spotTypeCountPie.getChartData();
                }
                else{
                    this.$refs.spotTypeCountBar.getChartData();
                }
                if(this.dropDownComponentForCategory == 0){
                    this.$refs.categoryCountPie.getChartData();
                }
                else{
                    this.$refs.categoryCountBar.getChartData();
                }
            },
            getPermissions() {
                this.selected_client_id = JSON.parse(localStorage.getItem('client_id'));
                this.form.client_id = this.selected_client_id;
                this.getPermissionsData = JSON.parse(localStorage.getItem('userPermissions'));
            },
            permissionExist(param) {
                var check = this.getPermissionsData.includes(param);
                if (check) {
                    return true;
                } else {
                    return false;
                }
            },
            getDashboardData() {
                API.getDashboardData(this.form,
                    data => {
                        this.total_spots = data.data.total_spots;
                        this.this_year = data.data.yearly_spots;
                        this.quarter_name = 'Q' + data.data.current_quarter;
                        this.quarter_count = data.data.quarterly_spots;
                        this.month_name = data.data.current_month_name;
                        this.month_count = data.data.monthly_spots;
                    },
                    err => {
                    }
                )
            },
            
            getTotalSpotsByFilter() {
                API.getTotalSpotsByFilter(this.form,
                    data => {
                        var array = JSON.parse(JSON.stringify(data))
                        console.log('array :', array);
                        this.totalSpots = array.data.totalFilterSpots;
                        console.log('this.totalSpots :', this.totalSpots);
                    },
                    err => {
                 
                    }
                )
            },
            getQuestionsData() {
                this.showQuestionData = false;
                API.getQuestionsData(this.form,
                    data => {
                        // this.questions = data.data;
                        this.showQuestionData = true;
                        this.questions = Object.values(data.data);
                        console.log('this.questions :', this.questions);
                        this.questions.forEach((q, index) => {
                            q.pieChart = true;
                            q.barChart = false;
                            // q.chartType = "pie";
                        });
                    },
                    err => {
                    }
                )
            },     
            filterData() {
                // this.$EventBus.$emit('filterByDate', this.form);
                this.getQuestionsData();
                this.getTotalSpotsByFilter();
                if(this.dropDownComponentForSpotsPerMonth == 1){
                    this.$refs.spotChartCanvasCountPerMonth.getFormData(this.form)
                }
                else{
                    this.$refs.spotChartPieCountPerMonth.getFormData(this.form)
                }
                if(this.dropDownComponentForLocation == 0){
                    this.$refs.spotLocationCountPie.getFormData(this.form)
                }
                else{
                    this.$refs.spotLocationCountBar.getFormData(this.form)
                }
                if(this.dropDownComponentForSpotType == 0){
                    this.$refs.spotTypeCountPie.getFormData(this.form)
                }
                else{
                    this.$refs.spotTypeCountBar.getFormData(this.form)
                }
                if(this.dropDownComponentForCategory == 0){
                    this.$refs.categoryCountPie.getFormData(this.form)
                }
                else{
                    this.$refs.categoryCountBar.getFormData(this.form)
                }
                // this.$refs.spotQuestionCountPie.getFormData(this.form) 
                // this.$refs.spotChartPieCountPerMonth.getFormData(this.form)
            }
        },
        mounted() { 
            this.form.project_id = this.$store.state.projectID;
        }, 
        computed: {
            // Use Vuex getter to get the project ID
            projectID() {
                return this.$store.state.projectID;
            },
        },
        beforeMount() {
            this.form.project_id = this.$store.state.projectID;
            this.getPermissions();
            // If projectID is available in store, fetch data for it
            const projectId = this.$store.state.projectID;
            if (projectId) {
                this.form.project_id = projectId;
                this.getDashboardData();
                this.getQuestionsData();
            } else {
                this.getDashboardData();
                this.getQuestionsData();
            }
        },
        watch: {
            projectID(newProjectID) {
                if (newProjectID) {
                    this.form.project_id = newProjectID;
                    this.getDashboardData();
                    this.getQuestionsData();
                }
            },
        },

    }
    
</script>

<style>
.total-spots-style{
    display: inline-block;
    padding: 8px 16px;
    background-color: #fff;
    text-align: center;
    color: #787878;
    font-weight: 500;
    font-size: 1.25rem;
    width: 100px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
}
.drop-down-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
text tspan {
    font-size: 14px;
}
.btns-flex {
    display: flex;
    align-items: center;
}
@media (max-width: 1200px) {
    text tspan {
        font-size: 12px;
    }
}
@media (max-width: 1150px) {
    .card .card-body {
        padding: 0px;
    }
}
@media (max-width: 767px) {
    .m-chart {
        max-width: 100% !important;
        flex: 0 0 100% !important;
    }
}
@media (max-width: 550px) {
    .mlt-0 {
        margin-left: 0 !important;
        margin-top: 0.5rem !important;
    }
    text tspan {
        font-size: 12px;
    }
}
@media (max-width: 425px) {
    text tspan {
        font-size: 10px;
    }
}
@media (max-width: 375px) {
    text tspan {
        font-size: 9px;
    }
}
</style>
